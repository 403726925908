import React, {createRef, useRef, useState} from 'react';
import FormGroup from "../elements/form-group";
import FormGroupSelect from "../elements/form-group-select";
import FormGroupTextarea from "../elements/form-group-textarea";
import Arrow from "../elements/arrow";
import ReCAPTCHA from "react-google-recaptcha";


function ContactFormSection({dataProvider}) {

    const siteKey = "6LctEOcUAAAAADbusysUKozmWClBgY_1z7lzeviQ";
    //const siteKey = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
    const DELAY = 1500;
    const actionURL = 'https://getform.io/f/d31acec2-0d39-4ffa-8d8a-de2fd67dc6a9'

    const [expired, setExpired] = useState(false)
    const [callback, setCallback] = useState(false)
    const [token, setToken] = useState(false)

    const reCaptchaRef = React.createRef();

    const handleChange = value => {
        setToken(value);
        // if value is null recaptcha expired
        if (value === null) setExpired(true);
    };

    const asyncScriptOnLoad = () => {
        setCallback('called');
    };

    const handleSubmit = (e) =>{
        e.preventDefault();
        const token = reCaptchaRef.current.getValue();

        if(token){
            document.getElementById('contact-form').submit();
        }
    }

    return (
        <section className="contact-form-section">
            <div className="container">
                <form onSubmit={handleSubmit} id={'contact-form'} action={actionURL} method={'post'}>
                    <div className={'grid grid-cols-1 md:grid-cols-2 md:gap-x-10 lg:grid-cols-3 lg:gap-x-16'}>
                        <FormGroup
                            id={'name'} label={dataProvider.getLabel('form-label-name')}
                            type={'text'} bg={`light-grey`} required={true}
                        />
                        <FormGroup
                            id={'website'} label={dataProvider.getLabel('form-label-website')}
                            bg={`light-grey`} required={true}
                        />
                        <FormGroup
                            id={'phone'} label={dataProvider.getLabel('form-label-phone')}
                            type={'phone'} bg={`light-grey`} required={true}
                        />
                        <FormGroup
                            id={'company'} label={dataProvider.getLabel('form-label-company')}
                            type={'text'} bg={`light-grey`} required={true}
                        />
                        <FormGroupSelect
                            id={'company-type'} label={dataProvider.getLabel('form-label-company-type')}
                            options={[dataProvider.getLabel('advertiser'), dataProvider.getLabel('publisher')]} bg={`light-grey`} required={true}
                        />
                        <FormGroup
                            id={'email'} label={dataProvider.getLabel('form-label-email')}
                            type={'email'} bg={`light-grey`} required={true}
                        />
                        <FormGroupTextarea
                            id={'message'} label={dataProvider.getLabel('form-label-message')}
                            class={'md:col-span-2'} bg={`light-grey`} required={true}
                        />
                    </div>

                    <div className={'mb-12'}>
                        <ReCAPTCHA
                            sitekey={siteKey}
                            ref={reCaptchaRef}
                            onChange={handleChange}
                            asyncScriptOnLoad={asyncScriptOnLoad}
                        />
                    </div>

                    <button disabled={token === false} className={'simple-button bg-yellow'}>
                        <div className={'flex items-center'}>
                            <div className={'basis-auto'}>
                                {dataProvider.getLabel('form-label-submit-message')}
                            </div>
                            <div className={'basis-auto pl-4'}>
                                <Arrow/>
                            </div>
                        </div>
                    </button>
                </form>

            </div>
        </section>
    );
}

export default ContactFormSection;
