import * as React from "react";
// import {Helmet} from "react-helmet";
import Layout from "../../components/layout";
import Map from "../../components/elements/map";
import { graphql } from "gatsby";
import ContactFormSection from "../../components/sections/contact-form-section";
import { DataProvider } from "../../contentful/data-provider";
import Seo from "../../components/seo";

const ContactPage = ({ data }) => {
	const dataProvider = new DataProvider(data);
	const mapPins = data.allContentfulMapPin.edges.map((mapPin) => {
		return {
			title: mapPin.node.title,
			lat: mapPin.node.address.lat,
			lng: mapPin.node.address.lon,
			displayAddress: mapPin.node.displayAddress,
		};
	});

	return (
		<Layout dataProvider={dataProvider}>
			<Seo dataProvider={dataProvider} title={`GlassView | ${dataProvider.getLabel("contact-us-label")}`} />
			<main className={"pt-24 lg:pt-24"}>
				<section className='pb-0 md:pb-20'>
					<div className={"container"}>
						<div className={"flex items-start flex-wrap justify-between"}>
							<div className={"basis-full mb-8 md:mb-0 lg:basis-4/5"}>
								<h2 className={"h2 text-black"}>{dataProvider.getLabel("we-love-to-hear-from-you-label")}</h2>
							</div>
						</div>
					</div>
				</section>
				<ContactFormSection dataProvider={dataProvider} />
				<Map addresses={mapPins} />
			</main>
		</Layout>
	);
};

export default ContactPage;

export const query = graphql`
	query ($locale: String!) {
		allContentfulMapPin(filter: { node_locale: { eq: $locale } }) {
			edges {
				node {
					id
					title
					displayAddress
					address {
						lat
						lon
					}
				}
			}
		}
		allContentfulMenu(filter: { node_locale: { eq: $locale } }) {
			edges {
				node {
					...Menus
				}
			}
		}
		allContentfulPage(filter: { contentful_id: { eq: "4pTLsnc7VUwPjMpt2xFHcC" }, node_locale: { eq: $locale } }) {
			edges {
				node {
					...CurrentPageDetails
				}
			}
		}
	}
`;

//comentedhead
