import React from "react"
import GoogleMapReact from 'google-map-react';
import BtnArrow from "./btn-arrow";

const isClient = typeof window !== 'undefined';

const Map = (props) => {

    const {
        addresses,
        googleMapsApiKey,
    } = props;


    const marker = require('../../images/map-marker.svg').default;

    function clickOnPin(lat,lng){
        //console.log('OK');
    }

    function createMapOptions(maps) {
        // next props are exposed at maps
        // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
        // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
        // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
        // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
        // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
        return {
            minZoom: 3,
            mapTypeControl: false,
            panControl: false,
            styles: [
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#e9e9e9"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 29
                        },
                        {
                            "weight": 0.2
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 18
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 16
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        },
                        {
                            "lightness": 21
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#dedede"
                        },
                        {
                            "lightness": 21
                        }
                    ]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 16
                        }
                    ]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "saturation": 36
                        },
                        {
                            "color": "#333333"
                        },
                        {
                            "lightness": 40
                        }
                    ]
                },
                {
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f2f2f2"
                        },
                        {
                            "lightness": 19
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#fefefe"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#fefefe"
                        },
                        {
                            "lightness": 17
                        },
                        {
                            "weight": 1.2
                        }
                    ]
                }
            ]
        };
    }

    return (
        <section className="map-section">
            <div className="map">
                { isClient && (
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: 'AIzaSyAx0lBHrzGCMwSAdEPlhgJ5UwCDlNjCQGI' }}
                        defaultCenter={[38.02, -80.09]}
                        defaultZoom={2}
                        options={createMapOptions}
                    >

                        { addresses.map((address, index) => {
                            return (
                                <div key={index}
                                    className="marker"
                                     style={{width: '50px', height: '52px', zIndex: index}}
                                    lat={address.lat}
                                    lng={address.lng}
                                     onClick={clickOnPin(address.lat,address.lng)}
                                >
                                    <div className={'marker-inner'}
                                         style={{backgroundImage:`url(${marker})`}}/>

                                    <div className={'infobox'}>
                                        <div className={'city'}>
                                            {address.title}
                                        </div>
                                        <div className={'address'}>
                                            {address.displayAddress}
                                        </div>
                                        <a href={`https://www.google.com/maps/place/${address.lat}+${address.lng}`} target={'_blank'} rel={'noreferrer'}>
                                            <BtnArrow title={'Open Map'} size={'btn-xxs'} class={'btn-yellow text-base'}/>
                                        </a>
                                    </div>
                                </div>
                            );
                        }) }
                    </GoogleMapReact>
                )}
            </div>
        </section>
    )
}

export default Map;
